.home-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
}
.home-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-hero {
  position: relative;
  padding-top: 128px;
}
.home-max-width {
  margin-top: var(--dl-space-space-threeunits);
}
.home-heading-container {
  flex: 1;
  display: flex;
  max-width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text {
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text03 {
  color: var(--dl-color-scheme-black);
  font-size: 18px;
  text-align: center;
  line-height: 1.44;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-primary {
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-gallery {
  width: 100%;
  display: flex;
  grid-gap: 20px;
  align-items: stretch;
}
.home-container1 {
  width: 25%;
  align-self: stretch;
}
.home-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.home-container2 {
  width: 25%;
  display: flex;
  grid-gap: 20px;
  align-items: stretch;
  flex-direction: column;
}
.home-image1 {
  width: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.home-image2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.home-container3 {
  width: 50%;
  display: flex;
  grid-gap: 20px;
  flex-direction: column;
}
.home-image3 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.home-container4 {
  width: 100%;
  display: flex;
  grid-gap: 20px;
}
.home-image4 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.home-image5 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.home-services {
  display: flex;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  background-color: var(--dl-color-scheme-lightbrown);
}
.home-max-width1 {
  flex-direction: column;
}
.home-heading-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-text-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text07 {
  color: var(--dl-color-scheme-brown);
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.home-controls {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr;
}
.home-icon {
  width: 16px;
  height: 16px;
}
.home-icon02 {
  width: 16px;
  height: 16px;
}
.home-cards-container {
  width: 100%;
  display: flex;
  grid-gap: 20px;
}
.home-max-width2 {
  flex-direction: column;
}
.home-text-container1 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text12 {
  color: var(--dl-color-scheme-brown);
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.home-text13 {
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text15 {
  color: var(--dl-color-scheme-black80);
  text-align: center;
  line-height: 26px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-tab-selector-header {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: center;
}
.home-text19 {
  margin-right: 30px;
}
.home-text20 {
  margin-right: 30px;
}
.home-navlink {
  margin-right: 30px;
  text-decoration: none;
}
.home-text21 {
  margin-right: 30px;
}
.home-text22 {
  margin-right: 30px;
}
.home-tab-selector-cards-container {
  width: 100%;
  display: grid;
  grid-row-gap: 40px;
  grid-column-gap: 20px;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
}
.home-about {
  background-color: var(--dl-color-scheme-lightbrown);
}
.home-max-width3 {
  flex-direction: row;
  justify-content: space-between;
}
.home-text-container2 {
  flex: 1;
  width: 40%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-text24 {
  color: var(--dl-color-scheme-brown);
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.home-text25 {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text29 {
  color: var(--dl-color-scheme-black80);
  line-height: 26px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-checklist {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-check-item {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-icon04 {
  fill: var(--dl-color-scheme-brown);
  width: 18px;
  height: 18px;
  margin-right: var(--dl-space-space-unit);
}
.home-text30 {
  font-style: normal;
  font-weight: 500;
}
.home-check-item1 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-icon06 {
  fill: var(--dl-color-scheme-brown);
  width: 18px;
  height: 18px;
  margin-right: var(--dl-space-space-unit);
}
.home-text31 {
  font-style: normal;
  font-weight: 500;
}
.home-check-item2 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-icon08 {
  fill: var(--dl-color-scheme-brown);
  width: 18px;
  height: 18px;
  margin-right: var(--dl-space-space-unit);
}
.home-text32 {
  font-style: normal;
  font-weight: 500;
}
.home-check-item3 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-icon10 {
  fill: var(--dl-color-scheme-brown);
  width: 18px;
  height: 18px;
  margin-right: var(--dl-space-space-unit);
}
.home-text33 {
  font-style: normal;
  font-weight: 500;
}
.home-check-item4 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-icon12 {
  fill: var(--dl-color-scheme-brown);
  width: 18px;
  height: 18px;
  margin-right: var(--dl-space-space-unit);
}
.home-text34 {
  font-style: normal;
  font-weight: 500;
}
.home-check-item5 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-icon14 {
  fill: var(--dl-color-scheme-brown);
  width: 18px;
  height: 18px;
  margin-right: var(--dl-space-space-unit);
}
.home-text35 {
  font-style: normal;
  font-weight: 500;
}
.home-image6 {
  flex: 1;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.home-process {
  background-color: var(--dl-color-scheme-lightblue);
}
.home-max-width4 {
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.home-text36 {
  color: var(--dl-color-scheme-white);
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.home-text37 {
  color: var(--dl-color-scheme-white);
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-step {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.home-text41 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.home-container5 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text42 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text43 {
  color: var(--dl-color-scheme-white80);
}
.home-step1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.home-text44 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.home-container6 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text45 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text46 {
  color: var(--dl-color-scheme-white80);
}
.home-step2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.home-text47 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.home-container7 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text48 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text49 {
  color: var(--dl-color-scheme-white80);
}
.home-max-width5 {
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-text50 {
  color: var(--dl-color-scheme-brown);
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.home-text51 {
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text53 {
  color: var(--dl-color-scheme-black80);
  text-align: center;
  line-height: 26px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-primary2 {
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-blog-cards-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-banner {
  background-color: var(--dl-color-scheme-brown);
}
.home-max-width6 {
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-text57 {
  color: var(--dl-color-scheme-white);
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.home-text58 {
  color: var(--dl-color-scheme-white);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text60 {
  color: var(--dl-color-scheme-white);
  text-align: center;
  line-height: 26px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-primary3 {
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-top-part {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.home-links-container {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-product-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text64 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text65 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text66 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-navigate-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text68 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text69 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text70 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-contact-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text72 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text73 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-subscribe-container {
  flex: 0 0 auto;
  width: 35%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text81 {
  color: var(--dl-color-scheme-brown);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-textinput {
  outline: none;
  align-self: stretch;
  padding-top: 4px;
  border-color: rgba(0, 0, 0, 0.1);
  padding-left: 0px;
  border-radius: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  padding-bottom: 4px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.home-separator {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: #D9D9D9;
}
.home-max-width8 {
  flex-direction: row;
  justify-content: space-between;
}
.home-image7 {
  width: 100px;
  object-fit: cover;
}
.home-text82 {
  align-self: center;
}
.home-text84 {
  font-weight: 700;
}
.home-text86 {
  font-weight: 700;
}
@media(max-width: 991px) {
  .home-heading-container {
    max-width: 100%;
    margin-bottom: 42px;
  }
  .home-gallery {
    flex-direction: column;
  }
  .home-container1 {
    width: 100%;
  }
  .home-container2 {
    width: 100%;
    flex-direction: row;
  }
  .home-container3 {
    width: 100%;
    align-items: stretch;
  }
  .home-tab-selector-cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .home-max-width3 {
    flex-direction: column;
  }
  .home-text-container2 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-image-container {
    width: 100%;
  }
  .home-image6 {
    width: 100%;
  }
  .home-blog-cards-container {
    align-items: center;
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .home-text {
    font-size: 48px;
  }
  .home-container2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .home-image1 {
    height: 100%;
  }
  .home-tab-selector-cards-container {
    grid-template-columns: 1fr;
  }
  .home-text41 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .home-text44 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .home-text47 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .home-top-part {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-links-container {
    width: 100%;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .home-subscribe-container {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .home-text41 {
    margin-right: var(--dl-space-space-twounits);
  }
  .home-text44 {
    margin-right: var(--dl-space-space-twounits);
  }
  .home-text47 {
    margin-right: var(--dl-space-space-twounits);
  }
  .home-top-part {
    align-items: center;
    flex-direction: column;
  }
  .home-contact-container {
    align-items: flex-start;
    margin-bottom: 0px;
  }
  .home-max-width8 {
    flex-direction: column;
  }
  .home-image7 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text82 {
    text-align: center;
  }
}
