.services-card-container {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: rgba(182, 71, 47, 0.1);
}
.services-card-image {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.services-card-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.services-card-text1 {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.services-card-text2 {
  cursor: pointer;
  text-decoration: underline;
}
