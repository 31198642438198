.portofolio-card-speaker-card {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.portofolio-card-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  min-width: 300px;
  max-height: 313px;
  min-height: 313px;
  align-items: flex-end;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: flex-end;
}
.portofolio-card-image {
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  position: absolute;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.portofolio-card-see-project-container {
  flex: 1;
  width: 100%;
  cursor: pointer;
  display: flex;
  opacity: 0;
  z-index: 1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(.jpg);
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
}
.portofolio-card-see-project-container:hover {
  opacity: 1;
}
.portofolio-card-first-name {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
}






