.navigation-header {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: var(--dl-color-scheme-white);
}
.navigation-max-width {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.navigation-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navigation-burger-menu {
  display: none;
}
.navigation-icon {
  fill: var(--dl-color-scheme-darkblue);
  width: 24px;
  height: 24px;
  display: flex;
}
.navigation-nav1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.navigation-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navigation-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation-icon2 {
  width: 24px;
  height: 24px;
}
@media(max-width: 991px) {
  .navigation-nav {
    display: none;
  }
  .navigation-burger-menu {
    display: flex;
  }
}
@media(max-width: 767px) {
  .navigation-max-width {
    padding: var(--dl-space-space-unit);
  }
}
